import "./App.css";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Peer from "peerjs";
import { v4 as uuid } from "uuid";
import Page404 from "./containers/Page404";
import * as freeice from "freeice";
import { Slideshow } from "./containers/SlideShow";
import { Tab } from "@headlessui/react";

let GLOBAL_ADS_VIDEO_FLAG = false;
let GLOBAL_ADS_IMAGES_FLAG = false;

let EMBED_FLAG = true;
let AD_VIDEO_LINK = "";
let AD_VIDEO_SRC = ""; //src;

let AD_IMAGE_DURATION = 30;

const USECASES = {
  VIDEO_DOORBELL: "VIDEO_DOORBELL",
  VEHICLE_TAG: "VEHICLE_TAG",
  PET_TAG: "PET_TAG",
  LOST_AND_FOUND_TAG: "LOST_AND_FOUND_TAG",
  LUGGAGE_TAG: "LUGGAGE_TAG",
  CONTACT_CARD: "CONTACT_CARD",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

var globalVar = 0;

function MainApp() {
  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00");
  const [ads, setAds] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const [position, setPosition] = useState({});
  const [hasPermissions, setHasPermissions] = useState(false);
  const [callState, setCallState] = useState(-1);
  const [intervalid, setIntervalid] = useState("");
  const [callObj, setCallObj] = useState("");
  const [partnerData, setPartnerData] = useState({});
  const [isHover, setIsHover] = useState(false);
  const [data, setData] = useState();
  const [actualConnectionId, setActualConnectionId] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const [remoteCamMuted, setRemoteCamMuted] = useState(true);
  const [hash, setHash] = useState("");
  const remoteVideoRef = useRef(null);
  const currentUserVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const navigate = useNavigate();

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      if (total === 0) {
        endcall(actualConnectionId);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
    }
  };

  useEffect(() => {
    globalVar = timer;
  }, [timer]);

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("10:00");
    window.localStorage.setItem("timer", timer);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setMinutes(deadline.getMinutes() + 10);
    return deadline;
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const tracksClose = () => {
    if (window.localstream) {
      const tracks = window.localstream.getTracks();
      if (tracks && tracks.length) {
        tracks.forEach((track) => {
          //if (track.kind === "video") {
          if (track.enabled) {
            track.stop();
            track.enabled = false;
          }
          // }
        });
      }
      let x;
      x = typeof window.localstream.getTracks()[0];
      if (window.localstream.getTracks().length && x === "MediaStreamTrack") {
        window.localstream.removeTrack(window.localstream.getTracks()[0]);
      }
      x = typeof window.localstream.getTracks()[0];
      if (window.localstream.getTracks().length && x === "MediaStreamTrack") {
        window.localstream.removeTrack(window.localstream.getTracks()[0]);
      }
    }
  };

  const baseurl = "https://api.kiot.io/";
  // const baseurl = "https://staging.kiot.io/";

  const boxStyle = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "20px",
    border: "3px solid rgb(127 219 148)",
    fontSize: "1.2rem",
  };

  const boxStyle1 = {
    height: "4rem",
    width: "4rem",
    borderRadius: "50%",
    backgroundColor: "rgb(215 68 68)",
    color: isHover ? "white" : "black",
    border: "2px solid #008CBA",
    borderColor: "rgb(215 68 68)",
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
    let _hash = window.location.href.split("=");
    if (_hash.length > 1) {
      if (typeof URLSearchParams !== "undefined") {
        const params = new URLSearchParams(
          new URL(window.location.href).search
        );
        params.delete("qr");
        window.history.pushState({}, document.title, "/" + params.toString());
      }
      window.localStorage.setItem("qr", hash[1]);
      setHash(_hash);
    } else {
      setLoading(false);
      setCallState(-2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scannedQRReportToServer = (position) => {
    let client_peer_id = checkCookie();
    axios
      .get(
        `${baseurl}peerjs/video_stream?kd_id=` +
          hash[1] +
          `&connection_id=` +
          client_peer_id +
          `&user_lati=${position.coords.latitude}&user_longi=${position.coords.longitude}`
      )
      .then((response) => {
        setLoading(false);
        let advertisements = {};
        GLOBAL_ADS_VIDEO_FLAG = response.data.advertisements?.video_flag;
        GLOBAL_ADS_IMAGES_FLAG = response.data.advertisements?.image_flag;
        AD_VIDEO_LINK = response.data.advertisements?.video?.link;
        AD_VIDEO_SRC = response.data.advertisements?.video?.src;
        AD_IMAGE_DURATION = response.data.advertisements?.images?.duration;
        if (GLOBAL_ADS_VIDEO_FLAG || GLOBAL_ADS_IMAGES_FLAG) {
          advertisements.enabled = true;
          if (GLOBAL_ADS_VIDEO_FLAG) {
            advertisements.video = {};
            if (AD_VIDEO_LINK) {
              advertisements.video.link = AD_VIDEO_LINK + "?autoplay=1&mute=1";
            }
            if (AD_VIDEO_SRC) {
              advertisements.video.link = AD_VIDEO_SRC;
            }
          }
          if (GLOBAL_ADS_IMAGES_FLAG) {
            advertisements.images = { duration: AD_IMAGE_DURATION * 1000 };
            advertisements.images.list =
              response.data.advertisements?.images?.list;
          }
        }
        setAds(advertisements);
        response.data.advertisements = advertisements;
        if (
          response.data.advertisements &&
          response.data.advertisements.enabled
        ) {
          setAds(response.data.advertisements);
        }
        setLoading(false);
        if (response.data.block_call) {
          navigate("/endcall", {
            state: {
              block_call: response.data.block_call,
              ads: response.data.advertisements,
              EMBED_FLAG: EMBED_FLAG,
            },
          });
        } else if (response.data.user_busy) {
          navigate("/endcall", {
            state: {
              user_busy: response.data.user_busy,
              ads: response.data.advertisements,
              EMBED_FLAG: EMBED_FLAG,
            },
          });
        } else {
          createPeer(client_peer_id);
          //below code is only for testing
          // let x = response.data;
          // x.qr_meta = {
          //   // usecase: "LOST_AND_FOUND_TAG",
          //   // usecase: "VIDEO_DOORBELL",
          //   // usecase: "PET_TAG",
          //   usecase: "LUGGAGE_TAG",
          //   pet_name: "Kukka",
          //   vehicle_number: "TS07 JV 2929",
          //   message:
          //     "Contact me if I lost my weird color bag. I will give you a reward. Thanks!",
          //   pet_type: "dog",
          //   // item_name: "Water Bottle",
          //   item_name: "Mokobara bag",
          // };
          setData(response.data);
          let logo = "";
          let theme = {
            light: 0,
            gold: "#A3C84F",
            gold_rgb: "163, 200, 79",
            gold_tint: "#A3C84F",
            gold_shade: "#A3C84F",
            orange: "#A3C84F",
            orange_rgb: "163, 200, 79",
            orange_tint: "#A3C84F",
            orange_shade: "#A3C84F",
          };
          console.log(logo, "Hello", theme);
          setPartnerData({ logo: logo, theme: theme });
        }
        //setRemotePeerIdValue('abcdefghij');//response.data.connection_id);
        //callPeer('abcdefghij')
      })
      .catch((err) => {
        setErrMsg(err?.response?.data?.message);
        setLoading(false);
        setCallState(-2);
      });
  };

  const checkForPermissions = () => {
    setLoading(true);
    // setHasPermissions(true);
    // scannedQRReportToServer({coords: {latitude: 0, longitude: 0}});
    hasMediaPermissions().then((hasPermissions) => {
      if (hasPermissions) {
        setHasPermissions(true);
      } else {
        askForPermissions();
      }
    });
  };

  function hasMediaPermissions() {
    if ("geolocation" in navigator) {
      return new Promise((resolve, reject) => {
        navigator.permissions
          .query({ name: "camera" })
          .then((result) => {
            if (result.state === "granted") {
              navigator.permissions
                .query({ name: "microphone" })
                .then((result) => {
                  if (result && result.state === "granted") {
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        setPosition(position);
                        scannedQRReportToServer(position);
                        return resolve(
                          position &&
                            position.coords &&
                            position.coords.latitude
                            ? true
                            : false
                        );
                      },
                      function (error) {
                        setLoading(false);
                        setCallState(-3); //when permissions are denied
                        setErrMsg(
                          "Please allow permissions to access location"
                        );
                        console.error(`you got an error: ${error}`);
                      }
                    );
                  }
                })
                .catch(() => resolve(false));
            } else {
              return resolve(false);
            }
          })
          .catch(() => resolve(false));
      });
    } else {
      return Promise.resolve(false);
    }
  }

  function askForPermissions() {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setPosition(position);
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            setHasPermissions(true);
            scannedQRReportToServer(position);
          })
          .catch((err) => {
            setLoading(false);
            setCallState(-2);
            console.error(`you got an error: ${err}`);
            setCallState(-3); //when permissions are denied
            setErrMsg(
              "Please allow permissions to access microphone and camera to make calls"
            );
          });
      },
      function (error) {
        setLoading(false);
        setCallState(-3); //when permissions are denied
        setErrMsg("Please allow permissions to access location");
        console.error(`you got an error: ${error}`);
        // Handle errors (e.g., permission denied or geolocation not available)
      }
    );
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers (you can also use 3959 miles for miles)

    // Convert latitude and longitude from degrees to radians
    const radLat1 = (Math.PI * lat1) / 180;
    const radLon1 = (Math.PI * lon1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const radLon2 = (Math.PI * lon2) / 180;

    // Haversine formula
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c * 1000; // Distance in mtrs

    return distance;
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "unknown";
    } else if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  };

  const saveContact = () => {
    let vcard = "";
    if (getMobileOperatingSystem() === "iOS") {
      vcard =
        "BEGIN:VCARD\nVERSION:4.0\nFN:" +
        data.qr_meta.item_name +
        (data.qr_meta.number_primary
          ? "\nTEL;TYPE=cell,voice:" + data.qr_meta.number_primary
          : "") +
        (data.qr_meta.number_secondary
          ? "\nTEL;TYPE=work,voice:" + data.qr_meta.number_secondary
          : "") +
        (data.qr_meta.email ? "\nEMAIL:" + data.qr_meta.email : "") +
        (data.qr_meta.wa
          ? "\nX-SOCIALPROFILE;TYPE=whatsapp:https://wa.me/+91" +
            data.qr_meta.wa
          : "") +
        (data.qr_meta.fb
          ? "\nX-SOCIALPROFILE;TYPE=facebook:" + data.qr_meta.fb
          : "") +
        (data.qr_meta.linkedin
          ? "\nX-SOCIALPROFILE;TYPE=linkedin:" + data.qr_meta.linkedin
          : "") +
        (data.qr_meta.insta
          ? "\nX-SOCIALPROFILE;TYPE=instagram:" + data.qr_meta.insta
          : "") +
        (data.qr_meta.X
          ? "\nX-SOCIALPROFILE;TYPE=twitter:" + data.qr_meta.X
          : "") +
        (data.qr_meta.website ? "\nURL:" + data.qr_meta.website : "") +
        "\nTITLE:" +
        ((data.qr_meta.company ? data.qr_meta.company + " | " : "") +
          data.qr_meta.title) +
        "\nEND:VCARD";
    } else {
      vcard =
        "BEGIN:VCARD\nVERSION:3.0\nFN:" +
        data.qr_meta.item_name +
        (data.qr_meta.number_primary
          ? "\nTEL;TYPE=cell,voice:" + data.qr_meta.number_primary
          : "") +
        (data.qr_meta.number_secondary
          ? "\nTEL;TYPE=work,voice:" + data.qr_meta.number_secondary
          : "") +
        (data.qr_meta.website ? "\nURL:" + data.qr_meta.website : "") +
        (data.qr_meta.email ? "\nEMAIL:" + data.qr_meta.email : "") +
        "\nTITLE:" +
        ((data.qr_meta.company ? data.qr_meta.company + " | " : "") +
          data.qr_meta.title) +
        "\nEND:VCARD";
    }
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  const callCheck = () => {
    if (data.co_ordinates) {
      let distance = calculateDistance(
        data.co_ordinates.lati,
        data.co_ordinates.longi,
        position.coords.latitude,
        position.coords.longitude
      );
      if (distance > 700 && data.qr_meta.usecase === USECASES.VIDEO_DOORBELL) {
        setCallState(-2);
        setErrMsg("Too far away from Home.");
      } else {
        callNow();
      }
    } else {
      callNow();
    }
  };

  const callNow = () => {
    getMedia();

    setCallState(1);

    document.body.style.overflow = 'hidden';
    let client_peer_id = getCookie("connection_id");
    let session_id = data.session_id;
    axios
      .get(
        `${baseurl}peerjs/video_call?kd_id=` +
          hash[1] +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          client_peer_id +
          `&lati=${position.coords.latitude}&longi=${position.coords.longitude}`
      )
      .then((response) => {
        //  setTimeout(()=>{
        if (response.data.block_call) {
          navigate("/endcall", {
            state: {
              block_call: response.data.block_call,
              ads: ads,
              EMBED_FLAG: EMBED_FLAG,
            },
          });
        } else if (response.data.user_busy) {
          navigate("/endcall", {
            state: {
              user_busy: response.data.user_busy,
              ads: ads,
              EMBED_FLAG: EMBED_FLAG,
            },
          });
        } else {
          setActualConnectionId(response.data.connection_id);
          callPeer(response.data.connection_id);
        }
      })
      .catch((err) => {
        setErrMsg(err?.response?.data?.message);
        //setLoading(false);
        setCallState(-2);
      });
  };

  function setCookie(cname, cvalue) {
    let date = new Date();
    date.setTime(date.getTime() + 399 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  function checkCookie() {
    let user = getCookie("connection_id");
    if (user && user.length === uuid().length) {
      return user;
    } else {
      let user = uuid();
      if (user !== "" && user != null) {
        setCookie("connection_id", user);
        return user;
      }
    }
  }

  const createPeer = (client_peer_id) => {
    const peer = new Peer(client_peer_id, {
      debug: 3,
      host: "peer.kiot.io",
      path: "/peerjs/myQRapp",
      secure: true,
      config: {
        iceServers: freeice(),
      },
    });
    // setLoading(false);
    peer.on("open", (id) => {
      // setPeerId(id)
    });
    peer.on("error", (err) => {
      console.log(peer, "PEER", err, "ERROR");
    });
    peer.on("connection", (conn) => {
      conn.on("iceStateChanged", () => {
        console.log("iceStateChanged");
        conn.close();
      });
      conn.on("error", (err) => {
        console.log(err, "CONN ERROR");
      });
      conn.on("close", () => {
        console.log("conn close event");
        // handlePeerDisconnect();
        tracksClose();
        if (callState !== -1) {
          setCallState(-1);
          goToEndpage();
        }
      });
      conn.on("data", function (raw_data) {
        console.log("data received", raw_data);
        try {
          let data = JSON.parse(raw_data);
          if (data && (data.cam_mute === true || data.cam_mute === false)) {
            setRemoteCamMuted(data.cam_mute);
            console.log(remoteCamMuted, data.cam_mute, "chudu");
          }
        } catch (e) {
          console.log("Invalid data", raw_data);
        }
      });
    });
    peerInstance.current = peer;
  };

  const endcall = () => {
    document.body.style.overflow = "auto";
    if (intervalid !== "" || window.localStorage.getItem("intervalid") !== "") {
      clearInterval(intervalid);
      clearInterval(parseInt(window.localStorage.getItem("intervalid")));
      setIntervalid("");
      window.localStorage.setItem("intervalid", "");
    }
    let client_peer_id = getCookie("connection_id");
    let session_id = data.session_id;
    let x = timer.split(":");
    let z = parseInt(x[1]) + parseInt(x[0]) * 60;
    let remainingTime = 600 - z;
    let minutes = parseInt((remainingTime / 60).toFixed());
    let seconds = remainingTime % 60;
    let duration =
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    let call_answered = true;
    if (callState === 1) {
      call_answered = false;
    }
    axios
      .get(
        `${baseurl}peerjs/end_video_call?kd_id=` +
          hash[1] +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          actualConnectionId +
          `&client_peer_id=${client_peer_id}`+
          `&duration=` +
          duration +
          `&call_answered=` +
          call_answered
      )
      .then((response) => {});

    if (callObj) {
      callObj.close();
    }
    tracksClose();
    if (currentUserVideoRef && currentUserVideoRef.current) {
      currentUserVideoRef.current.srcObject = null;
    }
    if (remoteVideoRef) {
      remoteVideoRef.current.srcObject = null;
    }
    //  peerInstance.current.disconnect();
    // peerInstance.current.destroy('9876543210');
    navigate("/endcall", {
      state: {
        data: data,
        timer: timer,
        callState: callState,
        ads: ads,
        EMBED_FLAG: EMBED_FLAG,
      },
    });
    setCallState(-1);
  };

  const getMedia = () => {
    navigator.getUserMedia = navigator.getUserMedia.bind(navigator);

    navigator.getUserMedia(
      { video: true, audio: true },
      (mediaStream) => {
        if (currentUserVideoRef.current) {
          currentUserVideoRef.current.srcObject = mediaStream;
          window.localstream = mediaStream;
          let myVideo = document.getElementById("my-video");
          if (myVideo) {
            myVideo.volume = 0;
          }
          currentUserVideoRef.current.addEventListener("canplay", () => {
            currentUserVideoRef.current.play();
            currentUserVideoRef.current.removeEventListener(
              "canplay",
              () => {}
            );
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
    console.log(remoteVideoRef, "initial");
  };

  const callPeer = (id1) => {
    if (intervalid === "") {
      let date = new Date();
      let id = setInterval(() => {
        let now = new Date();
        let r = now - date;
        if (new Date(r).getSeconds() >= 45) {
          clearInterval(intervalid);
          clearInterval(parseInt(window.localStorage.getItem("intervalid")));
          setIntervalid("");
          window.localStorage.setItem("intervalid", "");
          goToEndpage(true);
        } else {
          setCallState(1);
          call(id1);
        }
      }, 5000);

      console.log(id, "setinterval");
      window.localStorage.setItem("intervalid", id);
      setIntervalid(id);
    }
  };

  const call = (actualConnectionId) => {
    if (callState !== 2) {
      var constraints = {
        mandatory: {
          OfferToReceiveAudio: true,
          OfferToReceiveVideo: true,
        },
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      };
      const call = peerInstance.current.call(
        actualConnectionId,
        window.localstream,
        constraints
      );
      setCallObj(call);
      call.on("stream", (remoteStream) => {
        console.log("Receiving Stream", intervalid, remoteStream);
        window.remoteStream = remoteStream;
        if (
          intervalid !== "" ||
          window.localStorage.getItem("intervalid") !== ""
        ) {
          clearInterval(intervalid);
          clearInterval(parseInt(window.localStorage.getItem("intervalid")));
          setIntervalid("");
          window.localStorage.setItem("intervalid", "");
        }
        if (callState !== 2) {
          setCallState(2);
          var iframes = document.querySelectorAll("iframe");
          for (var i = 0; i < iframes.length; i++) {
            iframes[i].parentNode.removeChild(iframes[i]);
          }
        }
        remoteVideoRef.current.addEventListener("canplay", () => {
          console.log(remoteVideoRef, "Yello");
          if (remoteVideoRef && remoteVideoRef.current) {
            remoteVideoRef.current.play();
            remoteVideoRef.current.removeEventListener("canplay", () => {});
          }
        });
        remoteVideoRef.current.onerror = (e) => {
          console.error("Video error:", e);
        };

        setTimeout(() => {
          remoteVideoRef.current.srcObject = remoteStream;
        }, 1000);

        console.log(remoteStream, "remote", remoteVideoRef.current);

        clearTimer(getDeadTime());

        const conn = peerInstance.current.connect(actualConnectionId);
        conn.on("open", function () {
          // here you have conn.id
          conn.send("hi!");
        });
        conn.on("data", (data) => {
          // here you have conn.id
          console.log(data);
        });
      });
      call.on("close", () => {
        console.log("call close event");
        tracksClose();
        setCallState(-1);
        goToEndpage();
      });
    }
  };

  const goToEndpage = (no_answer) => {
    let client_peer_id = getCookie("connection_id");
    let session_id = data.session_id;
    let x = globalVar.split(":");
    let z = parseInt(x[1]) + parseInt(x[0]) * 60;
    let remainingTime = 600 - z;
    let minutes = parseInt((remainingTime / 60).toFixed());
    let seconds = remainingTime % 60;
    let call_answered = true;
    if (callState === 1 || no_answer) {
      call_answered = false;
    }
    tracksClose();
    let duration =
      (minutes > 9 ? minutes : "0" + minutes) +
      ":" +
      (seconds > 9 ? seconds : "0" + seconds);
    axios
      .get(
        `${baseurl}peerjs/end_video_call?kd_id=` +
          hash[1] +
          `&session_id=` +
          session_id +
          `&connection_id=` +
          actualConnectionId +
          `&client_peer_id=${client_peer_id}`+
          `&duration=` +
          duration +
          `&call_answered=` +
          call_answered
      )
      .then((response) => {});
    navigate("/endcall", {
      state: {
        data: data,
        timer: globalVar,
        no_answer: no_answer ? no_answer : null,
        ads: ads,
        EMBED_FLAG: EMBED_FLAG,
      },
    });
  };

  return (
    <div className="App App-header">
      {isLoading ? (
        <div>
          <div className={ads && ads.enabled ? "loader" : "loader"}></div>
          <div className="mt-1rem"> Loading... </div>
        </div>
      ) : (
        <div>
          {callState === -2 ? (
            <Page404 msg={errMsg} ads={ads} />
          ) : callState === -3 ? (
            <>
              <Page404 msg={errMsg} ads={ads} isShow={true} />
            </>
          ) : hasPermissions ? (
            <div>
              {/* <input type="text" value={remotePeerIdValue} onChange={e => setRemotePeerIdValue(e.target.value)} /> */}
              {callState !== 0 && (
                <div>
                  {callState === -1 && (
                    <>
                      <div className="fixed top-4 right-6">
                        <img
                          src={
                            `https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` +
                            data.partner_name +
                            `.png`
                          }
                          className="h-[5rem] inline"
                          alt=""
                        ></img>
                      </div>
                      <>
                        {(data &&
                          data.qr_meta &&
                          data.qr_meta.usecase === USECASES.VIDEO_DOORBELL) ||
                        (data && !data.qr_meta) ? (
                          <>
                            <div>
                              <div className="m-1-5rem">
                                <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                  <div className="pet-header">
                                    <div className="absolute top-6 left-4 text-left max-w-[60vw]">
                                      <div className="text-[1.4rem] text-white truncate font-bold">
                                        {data.home_name}
                                      </div>
                                      <div className="pet-name-under text-slate-100">
                                          Tap on call to connect with the home owner
                                      </div>
                                    </div>
                                    <div className="text-[5rem] absolute top-1 right-8">
                                      🏠
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button
                                type="button"
                                className="w-[97vw] m-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-lg px-5 py-4 text-center"
                                onClick={() => {
                                  //createPeer();
                                  callCheck();
                                }}
                              >
                                <i
                                  className="fa fa-phone"
                                  style={{
                                    position: "relative",
                                    right: "0.8rem",
                                    color: "#5cb05c",
                                  }}
                                ></i>
                                Call
                              </button>
                            </div>
                          </>
                        ) : null}
                        {data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.PET_TAG ? (
                          <>
                            <div className="m-1-5rem">
                              {data.qr_meta.pet_name && (
                                <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                  <div className="pet-header">
                                    <div className="absolute top-6 left-4 text-left">
                                      <div className="pet-name-new text-white">
                                        {data.qr_meta.pet_name}
                                      </div>
                                      {data.qr_meta.pet_breed && (
                                        <div className="pet-name-under text-slate-100">
                                          {data.qr_meta.pet_breed}
                                        </div>
                                      )}
                                      {(data.qr_meta.pet_age ||
                                        data.qr_meta.pet_gender) && (
                                        <div className="pet-name-under text-slate-100">
                                          {data.qr_meta.pet_age
                                            ? data.qr_meta.pet_age + " years | "
                                            : ""}
                                          {data.qr_meta.pet_gender}
                                        </div>
                                      )}
                                    </div>
                                    <div className="font-6rem absolute top-0 right-6">
                                      {data.qr_meta.pet_type === "dog" && (
                                        <>🐶</>
                                      )}
                                      {data.qr_meta.pet_type === "cat" && (
                                        <>😼</>
                                      )}
                                      {data.qr_meta.pet_type === "bird" && (
                                        <>🐦</>
                                      )}
                                      {data.qr_meta.pet_type === "rabbit" && (
                                        <>🐰</>
                                      )}
                                      {data.qr_meta.pet_type === "hamster" && (
                                        <>🐹</>
                                      )}
                                      {data.qr_meta.pet_type === "turtle" && (
                                        <>🐢</>
                                      )}
                                      {data.qr_meta.pet_type ===
                                        "guinea_pig" && <>🐹</>}
                                      {data.qr_meta.pet_type === "ferret" && (
                                        <>🐭 </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full px-2 py-5 sm:px-0">
                                    <Tab.Group>
                                      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                                        {(data.qr_meta.pet_details ||
                                          data.qr_meta.food_details) && (
                                          <Tab
                                            className={({ selected }) =>
                                              classNames(
                                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                                                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none",
                                                selected
                                                  ? "bg-white text-blue-700 shadow"
                                                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                                              )
                                            }
                                          >
                                            About
                                          </Tab>
                                        )}
                                        {(data.qr_meta.owner_name ||
                                          data.qr_meta.message) && (
                                          <Tab
                                            className={({ selected }) =>
                                              classNames(
                                                "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                                                "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none",
                                                selected
                                                  ? "bg-white text-blue-700 shadow"
                                                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                                              )
                                            }
                                          >
                                            Owner
                                          </Tab>
                                        )}
                                      </Tab.List>
                                      <Tab.Panels className="mt-2">
                                        {(data.qr_meta.pet_details ||
                                          data.qr_meta.food_details) && (
                                          <Tab.Panel
                                            className={classNames(
                                              "rounded-xl bg-white p-3 text-left",
                                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                                            )}
                                          >
                                            <div>
                                              {data.qr_meta.pet_details && (
                                                <>
                                                  <span className="font-bold">
                                                    About:
                                                  </span>{" "}
                                                  {data.qr_meta.pet_details}{" "}
                                                  <br />
                                                </>
                                              )}
                                              {data.qr_meta.food_details && (
                                                <>
                                                  <span className="font-bold">
                                                    Food:
                                                  </span>{" "}
                                                  {data.qr_meta.food_details}{" "}
                                                  <br />
                                                </>
                                              )}
                                            </div>
                                          </Tab.Panel>
                                        )}
                                        {(data.qr_meta.owner_name ||
                                          data.qr_meta.message) && (
                                          <Tab.Panel
                                            className={classNames(
                                              "rounded-xl bg-white p-3 text-left",
                                              "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                                            )}
                                          >
                                            <div>
                                              {data.qr_meta.owner_name && (
                                                <>
                                                  <span className="font-bold">
                                                    Owner:
                                                  </span>{" "}
                                                  {data.qr_meta.owner_name}{" "}
                                                  <br />
                                                </>
                                              )}
                                              {data.qr_meta.message && (
                                                <>
                                                  <span className="font-bold">
                                                    Owner's Message:
                                                  </span>{" "}
                                                  {data.qr_meta.message} <br />
                                                </>
                                              )}
                                            </div>
                                          </Tab.Panel>
                                        )}
                                      </Tab.Panels>
                                    </Tab.Group>
                                  </div>
                                </div>
                              )}
                            </div>

                            <button
                              type="button"
                              className="w-[97vw] m-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-lg px-5 py-4 text-center"
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                }}
                              ></i>
                              Call Owner
                            </button>
                          </>
                        ) : null}
                        {data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.VEHICLE_TAG ? (
                          <>
                            <div className="m-1-5rem">
                              <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                <div className="pet-header">
                                  <div className="absolute top-6 left-4 text-left">
                                    <div className="pet-name-new text-white">
                                      Vehicle Tag
                                    </div>
                                    {data.qr_meta.vehicle_number && (
                                      <div className="pet-name-under text-slate-100">
                                        {data.qr_meta.vehicle_number}
                                      </div>
                                    )}
                                  </div>
                                  <div className="font-6rem absolute top-0 right-6">
                                    🚘
                                  </div>
                                </div>
                                {data.qr_meta.message && (
                                  <div className="text-white px-4 text-left pb-6 pt-2">
                                    <span className="font-bold">
                                      Owner's message:
                                    </span>{" "}
                                    {data.qr_meta.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              type="button"
                              className="w-[97vw] m-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-lg px-5 py-4 text-center"
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                }}
                              ></i>
                              Call Owner
                            </button>
                          </>
                        ) : null}
                        {data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.LOST_AND_FOUND_TAG ? (
                          <>
                            <div className="m-1-5rem">
                              <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                <div className="pet-header">
                                  <div className="absolute top-6 left-4 text-left">
                                    <div className="pet-name-new text-white">
                                      {data.qr_meta.item_name ||
                                        "Lost & Found Tag"}
                                    </div>
                                    {data.qr_meta.owner_name && (
                                      <div className="pet-name-under text-slate-100">
                                        {data.qr_meta.owner_name}
                                      </div>
                                    )}
                                  </div>
                                  <div className="font-6rem absolute top-0 right-6">
                                    🔍
                                  </div>
                                </div>
                                {data.qr_meta.message && (
                                  <div className="text-white px-4 text-left pb-6 pt-2">
                                    <span className="font-bold">
                                      Owner's message:
                                    </span>{" "}
                                    {data.qr_meta.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              type="button"
                              className="w-[97vw] m-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-lg px-5 py-4 text-center"
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                }}
                              ></i>
                              Call Owner
                            </button>
                          </>
                        ) : null}
                        {data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.LUGGAGE_TAG ? (
                          <>
                            <div className="m-1-5rem">
                              <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                <div className="pet-header">
                                  <div className="absolute top-6 left-4 text-left">
                                    <div className="pet-name-new text-white">
                                      {data.qr_meta.item_name || "Luggage Tag"}
                                    </div>
                                    {data.qr_meta.owner_name && (
                                      <div className="pet-name-under text-slate-100">
                                        {data.qr_meta.owner_name}
                                      </div>
                                    )}
                                  </div>
                                  <div className="font-6rem absolute top-0 right-6">
                                    🧳
                                  </div>
                                </div>
                                {data.qr_meta.message && (
                                  <div className="text-white px-4 text-left pb-6 pt-2">
                                    <span className="font-bold">
                                      Owner's message:
                                    </span>{" "}
                                    {data.qr_meta.message}
                                  </div>
                                )}
                              </div>
                            </div>

                            <button
                              type="button"
                              className="w-[97vw] m-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-lg px-5 py-4 text-center"
                              onClick={() => {
                                //createPeer();
                                callCheck();
                              }}
                            >
                              <i
                                className="fa fa-phone"
                                style={{
                                  position: "relative",
                                  right: "0.8rem",
                                  color: "#5cb05c",
                                }}
                              ></i>
                              Call Owner
                            </button>
                          </>
                        ) : null}
                        {data &&
                        data.qr_meta &&
                        data.qr_meta.usecase === USECASES.CONTACT_CARD ? (
                          <>
                            <div className="m-1-5rem">
                              {data.qr_meta.item_name && (
                                <div className="bg-gradient-to-r from-sky-400 to-blue-600 w-[97vw] rounded-lg">
                                  <div className="pet-header">
                                    <div className="absolute top-6 left-4 text-left">
                                      <div className="pet-name-new text-white">
                                        {data.qr_meta.item_name}
                                      </div>
                                      {(data.qr_meta.number_primary ||
                                        data.qr_meta.number_secondary) && (
                                        <div className="pet-name-under text-slate-100">
                                          <a
                                            href={`tel:${data.qr_meta.number_primary}`}
                                          >
                                            {" "}
                                            {data.qr_meta.number_primary}{" "}
                                          </a>{" "}
                                          {data.qr_meta.number_primary &&
                                          data.qr_meta.number_secondary
                                            ? " | "
                                            : ""}{" "}
                                          <a
                                            href={`tel:${data.qr_meta.number_secondary}`}
                                          >
                                            {" "}
                                            {data.qr_meta.number_secondary}{" "}
                                          </a>
                                        </div>
                                      )}
                                      {data.qr_meta.email && (
                                        <div className="pet-name-under text-slate-100">
                                          <a
                                            href={`mailto:${data.qr_meta.email}`}
                                          >
                                            {" "}
                                            {data.qr_meta.email}{" "}
                                          </a>
                                        </div>
                                      )}
                                      {(data.qr_meta.company ||
                                        data.qr_meta.title) && (
                                        <div className="pet-name-under text-slate-100">
                                          {data.qr_meta.title
                                            ? data.qr_meta.title + " | "
                                            : ""}
                                          {data.qr_meta.company}
                                        </div>
                                      )}
                                    </div>
                                    <div className="font-6rem absolute top-0 right-6">
                                      📇
                                    </div>
                                  </div>
                                  <div className="w-full px-2 py-4 sm:px-0 rounded-b-lg mt-6 text-right social-strip">
                                    {data.qr_meta.website &&
                                      data.qr_meta.website.length && (
                                        <div
                                          className="inline mr-6"
                                          onClick={() => {
                                            window.open(data.qr_meta.website);
                                          }}
                                        >
                                          <i
                                            className="fa fa-globe text-3xl social-icn"
                                          ></i>
                                        </div>
                                      )}
                                    {data.qr_meta.wa &&
                                      data.qr_meta.wa.length && (
                                        <div
                                          className="inline mr-6"
                                          onClick={() => {
                                            window.open(
                                              "https://wa.me/+91" +
                                                data.qr_meta.wa
                                            );
                                          }}
                                        >
                                          <i
                                            className="fa fa-whatsapp text-3xl social-icn"
                                          ></i>
                                        </div>
                                      )}
                                    {data.qr_meta.linkedin &&
                                      data.qr_meta.linkedin.length && (
                                        <div
                                          className="inline mr-6"
                                          onClick={() => {
                                            window.open(data.qr_meta.linkedin);
                                          }}
                                        >
                                          <i
                                            className="fa fa-linkedin text-3xl social-icn"
                                          ></i>
                                        </div>
                                      )}
                                    {data.qr_meta.insta &&
                                      data.qr_meta.insta.length && (
                                        <div
                                          className="inline mr-6"
                                          onClick={() => {
                                            window.open(data.qr_meta.insta);
                                          }}
                                        >
                                          <i
                                            className="fa fa-instagram text-3xl social-icn"
                                          ></i>
                                        </div>
                                      )}
                                    {data.qr_meta.X &&
                                      data.qr_meta.X.length && (
                                        <div
                                          className="inline mr-6"
                                          onClick={() => {
                                            window.open(data.qr_meta.X);
                                          }}
                                        >
                                          <i
                                            className="fa fa-twitter text-3xl social-icn"
                                          ></i>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div
                              className="rounded-md shadow-sm w-[98vw] m-auto"
                              role="group"
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  saveContact();
                                }}
                                className="w-1/2 items-center px-7 py-4 text-[0.9rem] font-medium text-gray-900 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10  focus:text-blue-700 dark:bg-gray-800 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                              >
                                <i
                                  className="fa fa-save"
                                  style={{
                                    position: "relative",
                                    right: "0.5rem",
                                    color: "#fff",
                                  }}
                                ></i>
                                Save Contact
                              </button>
                              <button
                                onClick={() => {
                                  callCheck();
                                }}
                                type="button"
                                className="w-1/2 items-center px-7 py-4 text-[0.9rem] font-medium text-gray-900 bg-gradient-to-l from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 dark:bg-gray-800 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                              >
                                <i
                                  className="fa fa-phone"
                                  style={{
                                    position: "relative",
                                    right: "0.5rem",
                                    color: "#5cb05c",
                                  }}
                                ></i>
                                Call Now
                              </button>
                            </div>
                          </>
                        ) : null}
                      </>
                    </>
                  )}
                </div>
              )}
              <div>
                {currentUserVideoRef ? (
                  <video
                    style={{
                      // border: '1px solid',
                      borderRadius: "15px",
                      objectFit: "cover",
                      position: "absolute",
                      height: "25vh",
                      width: "200px",
                      bottom: "5rem",
                      right: "1px",
                      display: callState !== -1 ? "block" : "none",
                    }}
                    ref={currentUserVideoRef}
                    id="my-video"
                    muted="muted"
                    playsInline
                    webkit-playsinline="true"
                  />
                ) : null}
              </div>
              <div>
                <video
                  style={{
                    height: "100%", //"100vh",
                    width: "100%",
                    objectFit: "cover",
                    display: callState === 2 ? "block" : "none",
                  }}
                  ref={remoteVideoRef}
                  playsInline
                  webkit-playsinline="true"
                />
                {callState === 2 && (
                  <div
                    style={{
                      background: "rgb(179 179 179 / 0.5)",
                      fontSize: "1.3rem",
                      top: "0vh",
                      /* left: 50vw; */
                      position: "absolute",
                      width: "100%",
                      textAlign: "center",
                      padding: "0.4rem 0 0.35rem 0",
                    }}
                  >
                    {timer}
                  </div>
                )}
                {callState !== -1 && callState !== 2 ? (
                  <div
                    style={{
                      background: "#ffffff12",
                      height: "100%", //"100vh",
                      width: "400px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={ads?.enabled ? {} : {}}>
                      <div
                        style={{
                          border: "5px solid #f3f3f3",
                          borderRadius: "50%",
                          borderTop: "5px solid #3498db",
                          borderTopColor: partnerData.theme.gold,
                          width: "50px",
                          height: "50px",
                          animation: "spin 2s linear infinite",
                        }}
                      ></div>
                      <div> Calling... </div>
                    </div>
                  </div>
                ) : null}
                {callState !== -1 && (
                  <div
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      position: "absolute",
                      bottom: "-1rem",
                      width: "100%",
                    }}
                  >
                    <button
                      style={boxStyle1}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => endcall(actualConnectionId)}
                      className="button button2"
                    >
                      <i
                        className="fa fa-phone"
                        style={{
                          position: "relative",
                          right: "0.8rem",

                          fontSize: "2rem",
                        }}
                      ></i>
                    </button>
                  </div>
                )}
              </div>{" "}
            </div>
          ) : (
            <div style={{ overflow: "scroll !important" }}>
              <div
                style={{
                  fontWeight: 300,
                  fontSize: "2rem",
                  margin: "0px 3rem",
                }}
              >
                Permissions
              </div>
              <div
                style={{
                  height: "20rem",
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  style={{
                    margin: "0 auto",
                    border: "3px solid rgb(128, 128, 128)",
                    borderRadius: "15px",
                    /* justify-content: center; */
                    /* text-align: center; */
                    height: "100%",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "30%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-map-marker"
                    ></i>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "30%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-camera"
                    ></i>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      height: "30%",
                    }}
                  >
                    <i
                      style={{
                        top: "20%",
                        position: "relative",
                        fontSize: "3rem",
                        color: "rgb(128, 128, 128)",
                      }}
                      className="fa fa-microphone"
                    ></i>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: "1rem", margin: "0 3rem" }}>
                Please allow location, camera and microphone permissions for
                Video calling. Location permission is to secure call connection.
              </div>
              <div style={{ paddingTop: "2rem" }}>
                <button
                  style={boxStyle}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => checkForPermissions()}
                  className="button button2"
                >
                  Allow Permissions
                </button>
                <div
                  onClick={() => {
                    if (!isShow) {
                      var x = document.getElementsByTagName("BODY")[0];
                      x.style.overflow = "scroll";
                    }
                    setShow(!isShow);
                  }}
                  style={{
                    paddingTop: "1rem",
                    fontWeight: "500",
                    fontSize: "1rem",
                    color: "rgb(127, 219, 148)",
                    textDecoration: "underline",
                  }}
                >
                  Click for Instructions to Reset Permissions
                </div>
              </div>
              <div
                style={{
                  margin: "1rem 2.5rem",
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                {isShow && (
                  <>
                    <div>Android</div>
                    <li>
                      Tap on address box lock icon on top and select permissions
                      to reset.
                    </li>
                    <div>IOS</div>
                    <li>Kindly refresh page or scan the QR again to reset</li>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {ads && ads.enabled && (
        <div style={callState === 2 ? { display: "none" } : {}}>
          {ads.video && ads.video.link !== "" && (
            <div className="video-responsive" id="videodemo">
              {EMBED_FLAG ? (
                <iframe
                  // width="853"
                  // height="480"
                  src={ads.video.link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  // allowFullScreen
                  title="Embedded youtube"
                />
              ) : (
                <video controls autoPlay muted style={{ width: "100%" }}>
                  <source src={ads.video.link} type="video/mp4" />
                </video>
              )}
            </div>
          )}
          {callState !== 2 &&
            callState !== 1 &&
            ads.images &&
            ads.images.list.length && <Slideshow ads={ads} />}
        </div>
      )}
    </div>
  );
}

export default MainApp;
