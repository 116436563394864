import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import './EndCall.css';
import { Slideshow } from './SlideShow';

const EndCall = () => {
  const location = useLocation();
  const [timer, setTimer] = useState('00:00');
  console.log(location);
  useEffect(() => {
    document.body.style.overflow = 'auto';
    if (location?.state?.timer) {
      let x = location?.state?.timer.split(':');
      let z = parseInt(x[1]) + (parseInt(x[0]) * 60);
      let remainingTime = 600 - z;
      let minutes = parseInt((remainingTime / 60).toFixed());
      let seconds = remainingTime % 60;
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      );
    }
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }, [location?.state?.timer]);
  return (
    <div className="App App-header">
      <div>
        {
          location?.state?.block_call ?
            <div>
              <div>
                <div>
                  <i className="fa fa-ban banicon"></i>
                </div>
                <div className='enddiv'>Call Blocked</div>
              </div>
              {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
            </div> :
            location?.state?.user_busy ?
              <div>
                <div>
                  <div>
                    <i className="fa fa-ban banicon"></i>
                  </div>
                  <div className='enddiv'>Call Busy! Try again</div>
                </div>
                {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
              </div>
              :
              <div>
                <div>
                  <div>
                    <i className="fa fa-phone banicon"></i>
                  </div>
                  <div className='enddiv'>Call Ended</div>
                  {location?.state?.no_answer && <div>
                    <div>No one Answered Call</div>
                    <div>Scan QR and Try again!</div>
                    </div>}
                  {!location?.state?.no_answer && location?.state?.callState !== 1 && <div>Duration: {timer}</div>}
                </div>
                {/* {location?.state?.data?.partner_name && <img src={`https://kiot1.s3.ap-south-1.amazonaws.com/partner_logos/` + location?.state?.data?.partner_name + `.png`} className='logo' alt='Logo'></img>} */}
              </div>
        }
      </div>
      {
        location?.state?.ads && location?.state?.ads?.enabled &&
        <div className='mt-5rem'>{
          location?.state?.ads?.video?.link &&  location?.state?.ads?.video?.link !== "" &&
          <div className="video-responsive">
          {location.state.EMBED_FLAG ?  <iframe
          // width="853"
          // height="480"
          src={location.state.ads.video.link}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          // allowFullScreen
          title="Embedded youtube"
        /> : <video controls autoPlay muted style={{width:'100%'}}>
        <source src={location.state.ads.video.link} type="video/mp4"/>
      </video>
      }
            </div>
}
{
  location?.state?.ads?.images && location?.state?.ads?.images?.list?.length &&
   <Slideshow ads={location.state.ads}/>
}
        </div>
}
    </div>
  )
}

export default EndCall;
