import React from 'react'


export default class Page404 extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props){
    super(props);
    this.state = {
      os: "unknown"
    }
    this.getMobileOperatingSystem();
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.setState({"os": "unknown"});
    }

    else if (/android/i.test(userAgent)) {
      this.setState({"os": "Android"});
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({"os": "iOS"});
    }
}

  render() {
    return (
      <div id="content" className="p-5">
        {/* row */}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-center error-box">
                  <h2 className="font-xl"><strong><i className="fa fa-fw fa-warning fa-lg text-warning font-xl" />
                    Uh-Oh! </strong></h2>
                  <br />

                  <p className="lead">
                   { this.props.msg ? this.props.msg : "Invalid! Please Try Again"}
                  </p>
                  <br />

                  { this.props.isShow && <div className="well-lg padding-10">
                    <hr/>
                  <div
                  style={{
                    paddingTop: "1rem",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}  className="p-5"
                >
                  Instructions to Enable Permissions
                </div>
                <div
                className='p-5'
                style={{
                  textAlign: "left",
                  fontSize: "1rem",
                }}
              >
                {this.props.isShow && (
                  <>
                  {(this.state.os === "unknown" || this.state.os === "Android") ? <>
                    <div style={{fontSize: "1.2rem", fontWeight: "500"}}>Android</div>
                      <ul>
                        <li>
                          Open Phone Settings App -> Go to Apps -> Click your browser App (Eg: Chrome, Google, Firefox) that you are using -> Select Permissions.
                        </li>
                        <li>
                          If Location is shown under Not Allowed list, Tap on Location and select 'Ask every time' option.
                        </li>
                        <li>
                          If Camera is shown under Not Allowed list, Tap on Camera and select 'Ask every time' option.
                        </li>
                        <li>
                          If Microphone is shown under Not Allowed list, Tap on Microphone and select 'Ask every time' option.
                        </li>
                        <li>
                          Once you verify all three location, camera, microphone permissions are removed from Not allowed list, kindly try scanning QR again.
                        </li>
                      </ul>
                    <br/>
                    </> : null }
                    {(this.state.os === "unknown" || this.state.os === "iOS") ? <>
                    <div style={{fontSize: "1.2rem", fontWeight: "500"}}>iOS</div>
                      <li>
                        Open Phone Settings App -> Click your browser App (Eg: Safari, Chrome, Google) that you are using.
                      </li>
                      <li>
                        For Safari -> Tap on Location and select 'Ask' option, Tap on Camera and select 'Ask' option, Tap on Microphone and select 'Ask' option.
                      </li>
                      <li>
                        For other browser apps (EX: Google, Chrome) ->  Tap on Location and select 'Ask Next Time or When I Share' option, Tap and Enable Camera, Tap and Enable Microphone.
                      </li>
                      <li>
                        Once you verify all three location, camera, microphone permissions, kindly try scanning QR again.
                      </li>
                    </> : null }
                  </>
                )}
              </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
      </div>
    )
  }
}