/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import EndCall from './containers/EndCall';
import Page404 from './containers/Page404';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/endcall' element={<EndCall />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  </BrowserRouter>
);
