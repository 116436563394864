import React, { useEffect, useState, useRef } from 'react'
import './EndCall.css';

export function Slideshow(props) {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const colors = props.ads.images.list;//["red","blue","black"]
  const delay = props.ads.images.duration;//2500;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const showManifesto = (link)=> {
    window.open(link, '_system', 'location=yes'); return false;
    
    }

  useEffect(() => {
    console.log(colors,"Images");
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [colors, delay, index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((item, index) => (
            <>

        {item.link ? <img onClick={()=>showManifesto(item.link)} className="slide" key={index} src={item.src} alt=""></img> :
        <img className="slide" key={index} src={item.src} alt=""></img>}
        </>
        ))}
      </div>

      <div className="slideshowDots">
        {colors.length >1 && colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}